/* Fixes for Safari */

.input-group-append {
    display: inline;
}

.row::before{
    display: inline-block;
}
.row::after {
    display: inline-block;
}


/* Custom Styling */

.file-upload-input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    cursor: pointer;
}

.file-upload{
    border: 3px dashed #ffffff;
}

.file-upload-hover:hover{
    border: 3px dashed #009EDB;
    background-color: #e5f5fb;
}

.admin-content-card-link:hover {
    text-decoration: none;
    color: #3c3d3e;
}

.admin-content-card:hover{
    background: rgba(220, 220, 220, .7);
}
.admin-content-card-link {
    text-decoration: none;
    color: #3c3d3e;
}

.Toastify__toast {
    border-radius: 5px;
    font-weight: bold;
    text-align: center;
    margin: 30px;
}

.Toastify__toast--success{
    background: #4ab471 !important;
}

.loginLoader { 
    text-align: center;
    top: 50%;
    left: 50%;
    max-width: 500px;
    position: absolute;
    transform: translate(-50%, -50%);
}

.centerVert {
    text-align: center;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.pagination > li > a:hover {
    background-color: lightskyblue;
}

.dropdown-item{
    padding: .75rem 1.25rem;
}

.dropdown-header{
    padding: .75rem 1.25rem;
}

.dropdown-item:enabled {
    color: #212529 !important;
}

.dropdown-item:disabled {
    color: slategray !important;
    cursor: not-allowed
}

.dropdown-item:hover:enabled {
    background-color: #c1def4;
    font-weight: 450;
    border-radius: 3px;
}

.popover-header {
    font-size: 1.5rem;
    margin: 5px; 
}

.popover-body {
    font-size: 11px;
}

.table-sm > tr{
    padding: .3rem;
}

.table {
    white-space: nowrap;
}


.noBlue {
    color: #3c3d3e;
    font-weight: 500;
}

.noBlue:hover {
    /* color: #3c3d3e !important; */
    text-decoration: none !important;
    /* font-weight: 600 !important; */
}

.noBlue:focus {
    text-decoration: none;
    color: #3c3d3e;
}

.tab-card {
    background-color: whitesmoke;
    border-color: darkgray;
    padding: 20px;
    min-height: 400px;
}

.tab-container {
    margin: 1% 3%;
    min-height: 300px;
}

.cursor {
    cursor: pointer;
}

.compact-padding {
    padding: 5px 10px 3px !important;
    margin: 0px !important;
}

.detail-compact-padding {
    padding: 0px 10px 5px !important;
    margin: 0px !important;
}

.well {
    border: 1px solid #cfd0d2 !important;
    background-color: #ffffff59 !important;
}

.ql-no-bg {
    background-color: inherit !important;
}

.input-group {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    position: relative;
}

.input-group>.form-control {
    flex: 1;
    position: relative;
}

select.form-control {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%20fill%3D%22%23555555%22%20%0A%09%20width%3D%2224px%22%20height%3D%2224px%22%20viewBox%3D%22-261%20145.2%2024%2024%22%20style%3D%22enable-background%3Anew%20-261%20145.2%2024%2024%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cpath%20d%3D%22M-245.3%2C156.1l-3.6-6.5l-3.7%2C6.5%20M-252.7%2C159l3.7%2C6.5l3.6-6.5%22%2F%3E%0A%3C%2Fsvg%3E");
    padding-right: 25px;
    background-repeat: no-repeat;
    background-position: right center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
      
}

.center-text {
    text-align: center;
}

.align-right {
    text-align: right;
}

.add-account-btn {
    float: right;
    padding: 3px 5px 3px 5px;
    margin-top: -4px;
}

.page-link:hover {
    background-color: rgb(0,158,219) !important;
    color: white !important;
}

.invalid-input {
    border: solid 1px red;
}

.invalid-input:focus{
    border: solid 1px red;
}

.valid-input {
    border: solid 1px green;
}

.valid-input:focus{
    border: solid 1px green;
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}
  
.player {
    position: absolute;
    top: 0;
    left: 0;
}

.nav-stacked > li {
    width: 100%
}

.test-setting-description {
    color: #6c757d !important;
    top: 50%;
    position: absolute;
    transform: translateY(-50%);
    padding-left: 40px;
}

.card-header-collapse {
    padding: 0px;
    background-color: #4f6f8f;
    border: none;
}

.card-header-button {
    text-align: left;
    width: 100%;
    padding: 10px 20px;
    color: white;
    font-size: larger;
}

.card-header-button:focus {
    color: white;
}

.card-header-button:hover {
    color: gainsboro;
}

.card-error {
    border: 1px solid #dc3545;
}

.table-responsive {
    border: 0px transparent solid;
}

.dark-bg {
    background-color: #34495e;
    color: white;
}

.round-corners {
    border-radius: 4px;
}

.online-status {
    width: 100%;
    text-align: center;
    position: fixed;
    bottom: 0;
    z-index: 10;
    padding: 7px;
    color: white;
    font-weight: bold;
}

.bold {
    font-weight: bold;
}

.underline {
    text-decoration: underline;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.react-datepicker-time__input {
    line-height: 20px;
    font-size: 11px;
    margin-left: 5px !important;
}

.react-datepicker-time__caption {
    font-size: 11px;
}

.nav-tabs > li > p {
    border: none;
    border-bottom: 3px solid transparent;
    border-radius: 0;
    color: #9a9ca1;
    margin-right: 0;
    padding: 10px 15px 7px 15px;
}

.nav-tabs > li.active > p, .nav-tabs > li.active > p:hover, .nav-tabs > li.active > p:focus {
    color: #3c3d3e;
    background-color: transparent;
    border: none;
    border-bottom: 3px solid #2a94d6;
    cursor: default;
}

.fixed-pre{
    display: block;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 0px;
    margin: 0 0 10px;
    font-size: 14px;
    line-height: 16px;
    color: #2e4153;
    word-break: normal;
    word-wrap: normal;
    background-color: transparent;
    border: none;
    white-space: pre-line;
}